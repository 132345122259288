<template>
  <b-row v-if="question">
    <b-col>
      <div class="d-flex font-weight-bolder pb-2">
        <span>Câu hỏi {{ questionNumber }}:</span>
        <span class="ml-2">{{ question.name }}</span>
        <action-dropdown
          v-if="showAction"
          :value="question"
          :show_copy="false"
          :show_view="false"
          :show_edit="false"
          :show_delete="false"
          svg_icon="svg-icon"
          class="dropdown-question"
        >
          <template>
            <template v-if="isDetail">
              <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                <a class="navi-link text-primary" @click.stop="viewQuestion">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/eye.svg"
                    />
                  </span>
                  <span class="navi-text text-primary"
                    >Xem chi tiết câu hỏi</span
                  >
                </a>
              </b-dropdown-text>
            </template>
            <template v-else>
              <template v-if="showActionEdit">
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="editQuestion"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Chỉnh sửa câu hỏi</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider />
              </template>
              <b-dropdown-text
                tag="div"
                class="navi-item cursor-pointer"
                @click="deleteQuestion"
              >
                <a class="navi-link text-danger">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                    />
                  </span>
                  <span class="navi-text text-danger">Xóa câu hỏi</span>
                </a>
              </b-dropdown-text>
            </template>
          </template>
        </action-dropdown>
      </div>
      <template v-if="isSingle">
        <b-row>
          <b-col>
            <b-form-radio-group
              :id="`radio-group-${id}`"
              v-model="selected"
              :options="question.answers"
              :name="`radio-group-${id}`"
              size="lg"
              class="answer-radio ml-10"
              stacked
              disabled
              text-field="content"
            ></b-form-radio-group>
          </b-col>
        </b-row>
      </template>
      <template v-if="isMultiple">
        <b-row>
          <b-col>
            <b-form-checkbox-group
              :id="`checkbox-group-${id}`"
              :name="`checkbox-group-${id}`"
              v-model="selectedMultiple"
              :options="question.answers"
              value-field="id"
              stacked
              class="ml-10"
              disabled
              text-field="content"
            ></b-form-checkbox-group>
          </b-col>
        </b-row>
      </template>
      <template v-if="isText">
        <b-row>
          <b-col>
            <basic-input
              placeholder="Nhập câu trả lời"
              name="name"
              disabled
            ></basic-input>
          </b-col>
        </b-row>
      </template>
      <template v-if="isRating">
        <b-row>
          <b-col>
            <v-rating
              :length="ratingLength"
              readonly
              class="d-flex justify-content-between"
              :class="setWidth()"
            >
              <template v-slot:item="props">
                <b-button class="btn-rating mr-3">
                  <span>{{ props.index + rangeBegin }}</span>
                </b-button>
              </template>
            </v-rating>
            <div
              class="d-flex justify-content-between py-2"
              :class="setWidth()"
            >
              <span>{{ titleBegin }}</span>
              <span>{{ titleEnd }}</span>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-col>
  </b-row>
</template>
<script>
import { QUESTION_TYPES } from '@/core/plugins/constants';
export default {
  name: 'Question',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: [String, Number],
      default: QUESTION_TYPES.RATING,
    },
    question: {
      type: Object,
      default: () => {},
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    showActionEdit: {
      type: Boolean,
      default: true,
    },
    questionNumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedMultiple: [],
      selected: false,
      rating: 0,
      titleBegin: null,
      titleEnd: null,
      ratingLength: 0,
      rangeBegin: 0,
    };
  },
  computed: {
    isMultiple() {
      return this.type === QUESTION_TYPES.MULTIPLE;
    },
    isSingle() {
      return this.type === QUESTION_TYPES.SINGLE;
    },
    isText() {
      return this.type === QUESTION_TYPES.TEXT;
    },
    isRating() {
      return this.type === QUESTION_TYPES.RATING;
    },
  },
  watch: {
    question: {
      handler() {
        this.setupData();
      },
      deep: true,
    },
  },
  created() {
    this.setupData();
  },
  methods: {
    setupData() {
      if (this.question && this.question.answers.length && this.isRating) {
        const answer = this.question.answers[0];
        this.titleBegin = answer.titleBegin;
        this.titleEnd = answer.titleEnd;
        this.ratingLength = answer.rangeEnd - answer.rangeBegin + 1;
        this.rangeBegin = answer.rangeBegin;
      }
    },
    setWidth() {
      if (this.ratingLength >= 8) return 'mw-45';
      if (
        !this.ratingLength ||
        (this.ratingLength >= 5 && this.ratingLength <= 7)
      )
        return 'mw-30';
      return 'mw-20';
    },
    editQuestion() {
      this.$emit('edit', this.question);
    },
    deleteQuestion() {
      this.$emit('delete', this.question);
    },
    viewQuestion() {
      this.$emit('view', this.question);
    },
  },
};
</script>
<style lang="scss">
.answer-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01645a !important;
  border-color: #01645a !important;
}
.answer-radio .custom-control .custom-control-label span {
  font-size: 1rem !important;
}
.dropdown-question {
  margin-left: 24px;

  .btn {
    padding: 0 !important;

    .svg-icon {
      svg {
        width: 24px;
        height: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.btn-rating.active {
  background-color: #21a567 !important;
  border-color: #21a567 !important;
  color: #fff !important;
}

.btn-rating {
  background-color: #fff !important;
  border-color: #a3a3a3 !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.mw-50 {
  max-width: 50%;
}
.mw-30 {
  max-width: 30%;
}
.mw-45 {
  max-width: 45%;
}
.mw-20 {
  max-width: 20%;
}
</style>
